import React, { useEffect } from 'react';
import { useState } from '@hookstate/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../../common/API';
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import ClearUser from '../../common/ClearUser';
import { parseJwt } from '../../common/helpers';

const ForgotPassword = props => {
  const state = useState({
    Email: "", Password: "",
    UI: {
      IsLoading: false,
      validated: false
    }
  });

  useEffect(() => {

    ClearUser();
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {

      event.stopPropagation();

    } else {

      console.log("Continue to next process", state.value);



      try {
        state.UI.IsLoading.set(true);
        let query = "email=" + state.Email.value;

        state.UI.validated.set(true);
        let request = await API.getAction("account/forgotPassword", query);
        state.UI.IsLoading.set(false);
        let response = request.data;
        console.log("token", response.token);
        console.log("Parsed token", parseJwt(response.token));
        localStorage.setItem("LogedUser", response.token);
        props.history.push(Routes.SignIn.path);
      } catch (err) {
        console.log("Error", err);
        debugger;
        state.UI.Error.set(err);
        state.UI.IsLoading.set(false);
      }
    }

  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>

          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">Recuperar contraseña</h4>
                </div>
                <Form className="mt-4 shadow-sm"

                  noValidate validated={state.UI.validated.value} onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4 ">
                    <Form.Label>E-Mail</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com"
                        value={state.Email.value}
                        onChange={(e) => { state.Email.set(e.target.value) }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Favor entrar un correo valido
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100"
                    disabled={state.UI.IsLoading.value}
                  >
                    {state.UI.IsLoading.value ?

                      <span className="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true"></span> : null}
                    Recuperar
                  </Button>
                </Form>



              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default ForgotPassword;