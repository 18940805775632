import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { Modal, Col, Row, Form } from "react-bootstrap";

import API from "../../common/API";
import { v4 as uuidv4 } from "uuid";

import { useFormik } from "formik";
import { parsedUser } from "../../common/GetCurrentUser";
import moment from "moment";

// import "./organization.css"
//Translation
import { useTranslation } from "react-i18next";

//Validation
const validationSchema = yup.object({
  name: yup.string().required(),
  identifier: yup.string().required(),
  country: yup.string().required(),
  email: yup.string().required(),
});
///Component

const AddEditOrganization = (props) => {
  const [state, setState] = useState({
    UI: {
      Validated: false,
      isLoading: false,
    },
  });

  const [user, setUser] = useState(null);

  const { t } = useTranslation("global");

  useEffect(() => {
    setUser(parsedUser());
  }, []);

  const handleSubmit = async (obj, event) => {
    console.log("values to submit", obj);
    let currentUser = parsedUser();
    debugger;
    if (obj.id.length === 0) {
      obj.id = uuidv4();
      obj.created = moment().format("YYYY-MM-DD HH:mm:ss");

      obj.creatorId = user.id;
    }
    setState({
      ...state,
      UI: {
        ...state.UI,
        isLoading: !state.UI.isLoading,
      },
    });
    let request = await API.postAction(
      "Organization/AddEdit?currentUserId=" + currentUser.ID,
      obj
    );
    setState({
      ...state,
      UI: {
        ...state.UI,
        isLoading: !state.UI.isLoading,
      },
    });
    console.log("response from adding", request);
    if (request.status === 200) {
      props.toggle(true);
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          identifier: "",
          city: "",
          country: "",
          id: "",
          organizationTimeZone: "SA Western Standard Time",
        },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });
  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("generic.organization")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6} className="mb-2">
              <Form.Label>
                {t("generic.id")} / {t("generic.rnc")}
              </Form.Label>
              <Form.Control
                type="text"
                name="identifier"
                value={formik.values.identifier ? formik.values.identifier : ""}
                onChange={formik.handleChange}
              />
              {formik.errors.identifier ? (
                <div className="invalid text-sm">
                  {formik.errors.identifier}
                </div>
              ) : null}
            </Col>
            <Col xs={12} md={6} className="mb-2">
              <Form.Label>{t("generic.name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={state.UI && state.UI.isLoading}
          >
            {t("generic.save")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditOrganization;
