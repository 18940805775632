import React, { useEffect, useState } from "react"
import Settings from "../../common/Settings"
import { Card } from "react-bootstrap"
import moment from "moment"
import VisitListItem from "./VisitListItem"

const VisitList = props => {
  const [visits, setVisits] = useState([])

  useEffect(() => {
    setVisits(props.visits)
  }, [props])

  console.log(visits)
  return (
    <>
      {" "}
      {visits &&
        visits.map((item: any, index: number) => {
          console.log(item)
          return <VisitListItem key={index} model={item} />
        })}
      {visits && visits.length === 0 && (
        <p className="text-muted my-4 text-center">
          No hay visitas registradas
        </p>
      )}
    </>
  )
}

export default VisitList
