
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

//App Styles
import "./css/app.css";
import i18next from "i18next"
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import '@hookstate/devtools'
import { resources } from './common/languages';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  lng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "es_DO",
  resources: resources,
})


const container = document.getElementById('root');
const root = createRoot(container);
root.render(  <Router>

  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
</Router>);

// <Route path="/" component={Islogin() ? App : Login} exact/>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
serviceWorkerRegistration.unregister();