import SettingSectionDTO from "./SettingSectionDTO";

const SettingSetions : SettingSectionDTO[]= [
	{
		name: "administration",
		parentId: "admin",
		requiredParent: false,
		sections: [
			{
				reference: "users",
				label: "users",
			},
			{
				reference: "roles",
				label: "roles",
			},
			{
				reference: "stages",
				label: "stages",
			},
			{
				reference: "categories",
				label: "categories",
			},
			{
				reference: "status",
				label: "status",
			},
			
		]
	},
]

export default SettingSetions;