import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";


import Visits from "../Visits/Visits";
import { useHistory, useParams } from "react-router-dom";
import Conversations from "../Chat/Conversations";
import { Routes } from "../../routes";
import VisitDetails from "../Visits/VisitDetails";


const DashboardOverview = props => {

    const history = useHistory();
    const { chatId, placeId } = useParams();

    const [chatShow, setChatShow] = useState(false);
    const toggleChat = () => {
        setChatShow(!chatShow)
        if (chatShow) {
            history.push(Routes.Home.path);
        }
    }


    const [placeShow, setPlaceShow] = useState(false);
    const togglePlace = () => {
        setPlaceShow(!placeShow)
        if (placeShow) {
            history.push(Routes.Home.path);
        }
    }
    useEffect(() => {
        console.log(placeId)
        if (placeId) {
            togglePlace();
        } else {
            setPlaceShow(false)
        }
    }, [placeId])


    useEffect(() => {
        console.log(chatId)
        if (chatId) {
            toggleChat();
        } else {
            setChatShow(false)
        }
    }, [chatId])

    return (<>
        {chatShow && <Conversations chatId={chatId} toggle={toggleChat} show={chatShow} />}
        {placeShow && <VisitDetails model={{ id: placeId }} toggle={togglePlace} show={placeShow} />}

        <Visits />
    </>)
}

export default DashboardOverview;