//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "ShearlyBI",
    Name: "ShearlyBI",

    baseURl: "./",
    //    BasePathForAPI: "https://app.pleelo.com/api/",//
    //BasePathForAPI: "https://vo.shearly.com/api/",//local on shearly
    BasePathForAPI: "https://api.bi.shearly.com/api/",
    BasePath: "https://api.bi.shearly.com/",
    GoogleMapsKey: "AIzaSyA5c0WhOqMatPEDmy2RGIBfvhSR9GFOr5k",

    SignalURL: "https://signal2.shearly.com/communicationHub",

};

export default Settings;
