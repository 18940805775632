import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Pagination from "../../../components/Common/Pagination";
import { parsedUser } from "../../../common/GetCurrentUser";
import API from "../../../common/API";
const CustomersComponent = (props) => {
  // PROPERTIES
  const [records, setRecords] = useState([]);
  const user = parsedUser();
  // METHODS
  const requestRecords = async () => {
    let Query = "OrganizationId=" + user.OrganizationId;
    // if (filter.from) {
    // 	Query += `&from=${moment(filter.from).format('YYYY-MM-DD')}&until=${moment(filter.until).format('YYYY-MM-DD')}`;
    // }
    // if (filter.sector) {
    // 	Query += `&sector=${filter.sector}`;
    // }
    // if (filter.name) {
    // 	Query += `&name=${filter.name} `;
    // }

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customer/list", Query);

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response;
      setRecords(result);
    }
  };

  // EVENTS

  // UI
  return (
    <Container className="mt-4">
      <Row>
        <h4>
          Clientes
          <button className="btn btn-sm" type="button">
            <i className="fa fa-plus"></i>
          </button>
        </h4>
        <div className="card shadow-sm p-0">
          <div className="card-body p-0">
            <div className="table-responsive m-0">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Referencia</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((item, index) => {
                      return <td key={index}>{}</td>;
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <Pagination />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default CustomersComponent;
