import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";
import { useTranslation } from "react-i18next";

const AssignPlaceStatus = (props) => {
  const user = parsedUser();
  const [records, setRecords] = useState([]);
  const {t}= useTranslation('global');
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId;

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("placeStatus/list", Query);

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response;
      setRecords(result);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  const handleAssign = async (item) => {
    
    let Query = `placeId=${props.place.id}&placeStatusId=${item? item.id: ""}`;
     await API.getAction("place/AssignPlaceStatus", Query);
    props.toggle();
  };
  return (
    <>
      <Modal show={props.show} onHide={props.toggle}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Status')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                {records &&
                  records.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <i className={"fa fa-circle me-2 "} style={{
                            color: item.color
                          }}></i>
                       
                          {item.name}
                        </td>
                        <td className="text-end">
                          {item.id !== props.place.assignedTo && (
                            
                          
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              handleAssign(item);
                            }}
                          >
                            {t('Assign')}
                          </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
     
      </Modal>
    </>
  );
};

export default AssignPlaceStatus;
