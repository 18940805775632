import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import Settings from "../../common/Settings";
import PinComponent from "../Common/PinComponent";

const VisitMap = (props) => {
	
  const [model, setModel] = useState(props.model);
  const GoogleMapRef = React.createRef();
  const [theMap, setTheMap] = useState(null);
	const [theMapsReference, setTheMapsReference] = useState(null);
	const [zoom, setZoom] = useState(19);
	const handleApiLoaded = (map, mapsReference) => {
		setTheMap(map);
		setTheMapsReference(mapsReference);
	};

// function to get contacatenate the address properly
const getFullAddress = (model) => {
  let address = model.address
  if (model.address2) {
    address += ", " + model.address2
  }
  if (model.sector) {
    address += ", " + model.sector
  }
  if (model.city) {
    address += ", " + model.city
  }
  if (model.region) {
    address += ", " + model.region
  }
  if (model.country) {
    address += ", " + model.country
  }
  return address
}

	useEffect(()=>{
setModel(props.model)
	},[props.model])
  return (
    <Card>
      <Card.Header className="px-2 p-1">
        <h5 className="d-inline">Mapa</h5>
        <button
															className="btn  btn-sm ms-2"
															type="button"
															onClick={() => {
																let fullAddress = "";
																fullAddress = `${model.latitude},${model.longitude}`
																//`${model.item.streetAddress ?? ""}, ${model.item.city ?? ""}, ${model.item.state ?? ""}, ${model.item.country}`;
																window.open(
																	`https://www.google.com/maps/search/?api=1&query=${fullAddress
																	}`,
																	"MapWindow",
																	"width=1280,height=840"
																);
															}}>
                              <i className="fa-solid fa-map-pin"></i>
														</button>
      </Card.Header>
      <Card.Body className="p-0">
        <GoogleMapReact
          ref={GoogleMapRef}
          // onClick={this.handleClick}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          bootstrapURLKeys={{ key: Settings.GoogleMapsKey }}
          style={{ width: "100%", height: "300px", position: "relative" }}
          center={{ lat: model.latitude, lng: model.longitude }}
          defaultZoom={zoom}
        >
          {model.longitude && (
            <PinComponent
              Type={"Visit"}
              lat={model.latitude}
              item={model}
              // toggle={toggleDetails}
              lng={model.longitude}
              text={model.name}
            />
          )}
        </GoogleMapReact>
      </Card.Body>
      <Card.Footer>
									{getFullAddress(model)}
														
												
									</Card.Footer>
    </Card>
  );
};
export default VisitMap;
