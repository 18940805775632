import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const CategoryComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("categories") ?? ""}
      getRecordsPath={`category/list?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`category/AddEdit?refer=${window.location.host}`}
      deleteRecordPath="category/Delete"
      fieldsList={[
        {
          name: "name",
          title: t("name"),
        },
        {
          name: "icon",
          title: t("icon"),
          htmlType: "icon",
          cssClass: "text-center",
        },
       
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "icon",
          htmlType: "text",
          dbType: "string",
          title: t("icon") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
     
      ]}
    />
  )
}

export default CategoryComponent
