import React, { useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import API from "../../common/API";

const VisitGallery = props => {
	const [images, setImages] = useState([]);

	const bindData = async ()=>{

		if(!props.id){

			return
		}
		let query = "id=" + props.id;
		// query += "&OrganizationId=" + user.OrganizationId;
		let requestAPI = await API.getAction("place/ListImages", query);
		if (requestAPI.data.status === "ok") {
console.log(requestAPI);
let response = requestAPI.data.response.map(item=>{
	item.loading="lazy";
	item.thumbnailHeight="100";
	item.thumbnailWidth="100";
	return (item)
}
)
			setImages(response);
		}
	}
	useEffect(() => {
		bindData();
	},[])
	if(images && images.length ===0){
		return <></>
	}
	return  <ReactImageGallery items={images} loading="lazy" />;
};
export default VisitGallery;