import React, { useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"
import PinComponent from "../Common/PinComponent"

import Settings from "../../common/Settings"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import moment from "moment"
import { Routes } from "../../routes"
import { useHistory, useParams } from "react-router-dom"
import AssignUser from "../Users/AssignUser"
import { Card, Col, Row } from "react-bootstrap"
import SummaryByStage from "./SummaryByStage"
import AddEditPlace from "./AddEditPlace"
import { useTranslation } from "react-i18next"
import SummaryByStatus from "./SummaryByStatus"
import VisitHistory from "./VisitHistory"

const Visits = props => {
  const history = useHistory()

  const [showHistory, setShowHistory] = useState(true)
  const [displayMode, setDisplayMode] = useState("map")
  const [showFilter, setShowFilter] = useState(false)
  const user = parsedUser()
  const [records, setRecords] = useState([])
  const GoogleMapRef = React.createRef()
  const [mapMode, setMapMode] = useState(null) //This is used to set the mode of the mode of the map; null = nothing; drawPolygon = allow to draw
  const [thePolygon, setThePolygon] = useState(null)
  const [filter, setFilter] = useState({
    from: null,
    until: null,
    sector: "",
    name: "",
    stageId: null,
    categoryId: null,
    assignedTo: null,
  })
  const [theHeatMapOpts, setTheHeatMapOpts] = useState({
    positions: [],
    options: {
      radius: 30,
      opacity: 0.6,
    },
  })
  const [state, setState] = useState({
    center: { lat: 18.479777, lng: -69.9341112 },
    zoom: 10,
    Devices: [],
    Pins: [],
    Geofences: [],
    DeviceSelected: null,
    Events: [],
    UI: {
      showAddEditGeofence: false,
      GeofenceSelected: null,
    },
  })
  const [theMap, setTheMap] = useState(null)
  const [theMapsReference, setTheMapsReference] = useState(null)
  const [zoom, setZoom] = useState(19)
  const { t } = useTranslation("global")
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }

  const centerMap = () => {
    if (avoidAutoZoom === true) {
      return
    }
    if (theMapsReference) {
      const bounds = new theMapsReference.LatLngBounds()

      records.forEach(marker => {
        if (marker.latitude) {
          bounds.extend(
            new theMapsReference.LatLng(marker.latitude, marker.longitude)
          )
        }
      })

      theMap.fitBounds(bounds)
      //theMap.setZoom(zoom);
    }
  }
  if (state.center === undefined) {
  }

  const toggleDetails = obj => {
    history.push(Routes.PlaceDetails.clean + obj.id)
  }
  const [sectors, setSectors] = useState([])
  const requestSectors = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    if (filter.from) {
      Query += `&from=${moment(filter.from).format(
        "YYYY-MM-DD"
      )}&until=${moment(filter.until).format("YYYY-MM-DD")}`
    }
    if (filter.sector) {
      Query += `&sector=${filter.sector}`
    }
    if (filter.name) {
      Query += `&name=${filter.name} `
    }

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("place/sectorsWithCount", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setSectors(result)
    }
  }

  // Main request
  const request = async avoidZoom => {
    if (avoidZoom) {
      setAvoidAutoZoom(true)
    }
    if (!user) {
      history.push(Routes.SignIn.path)
    }
    let Query = "OrganizationId=" + user.OrganizationId
    if (filter.from) {
      Query += `&from=${moment(filter.from).format(
        "YYYY-MM-DD"
      )}&until=${moment(filter.until).format("YYYY-MM-DD")}`
    }
    if (filter.sector) {
      Query += `&sector=${filter.sector}`
    }
    if (filter.stageId) {
      Query += `&stageId=${filter.stageId}`
    }
    if (filter.categoryId) {
      Query += `&categoryId=${filter.categoryId} `
    }

    if (filter.placeStatusId) {
      Query += `&placeStatusId=${filter.placeStatusId} `
    }

    if (filter.assignedTo) {
      Query += `&assignedTo=${filter.assignedTo} `
    }

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("place/list", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)
    }
  }
  const [placeStatus, setPlaceStatus] = useState([])
  const requestStatus = async () => {
    let Query = "OrganizationId=" + user.OrganizationId

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("placeStatus/list", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setPlaceStatus(result)
    }
  }
  const [stages, setStages] = useState([])
  const requestStages = async () => {
    let requestAPI = await API.getAction(
      "Stage/List?OrganizationId=" + user.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setStages(requestAPI.data.response)
    }
  }

  const [categories, setCategories] = useState([])
  const requestCategories = async () => {
    let requestAPI = await API.getAction(
      "category/List?OrganizationId=" + user.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setCategories(requestAPI.data.response)
    }
  }

  const [users, setUsers] = useState([])
  const requestUsers = async () => {
    let requestAPI = await API.getAction(
      "users/List?OrganizationId=" + user.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setUsers(requestAPI.data.response)
    }
  }

  const [polygon, setPolygon] = useState([])
  const [avoidAutoZoom, setAvoidAutoZoom] = useState(false)
  const [selectedPlaces, setSelectedPlaces] = useState([])

  //Read all the markers between the polygon definition
  const getPlacesByPolygon = () => {
    let places = []
    records.forEach(item => {
      let exist = theMapsReference.geometry.poly.containsLocation(
        { lat: item.latitude, lng: item.longitude },
        thePolygon
      )

      if (exist) {
        places.push(item)
      }
    })

    setSelectedPlaces(places)
  }
  const handleClickOnMap = obj => {
    if (mapMode === "drawPolygon") {
      setPolygon([
        ...polygon,
        {
          lat: obj.lat,
          lng: obj.lng,
        },
      ])
    }
  }

  useEffect(() => {
    if (!records.length > 0) {
      return
    }
    let points = []
    let pins = []
    records.forEach(item => {
      points.push({
        lat: item.latitudeStart,
        lng: item.longitudeStart,
      })

      pins.push({
        PositionType: "Visita",
        latitude: item.latitudeStart,
        longitude: item.longitudeStart,
        item: item,
      })
    })
    setState({
      ...state,
      Pins: pins,
    })
    setTheHeatMapOpts({
      ...theHeatMapOpts,
      positions: points,
    })

    if (records && records.length > 0 && avoidAutoZoom === false) {
      centerMap()
    } else {
      setAvoidAutoZoom(false)
    }
  }, [records])

  useEffect(() => {
    if (mapMode === null) {
      setPolygon([])

      setSelectedPlaces([])
    } else if (mapMode === "drawPolygon") {
    }
  }, [mapMode])

  useEffect(() => {
    if (polygon.length > 0) {
      thePolygon?.setMap(null)
      if (theMapsReference) {
        setThePolygon(
          new theMapsReference.Polygon({
            paths: polygon,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
          })
        )
      }
    } else {
      thePolygon?.setMap(null)
    }
  }, [polygon])

  useEffect(() => {
    thePolygon?.setMap(theMap)
  }, [thePolygon])
  useEffect(() => {
    request()
    requestSectors()
    requestStages()
    requestCategories()
    requestUsers()

    requestStatus()
  }, [])

  const [showAssignUsers, setShowAssignUsers] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState(null)
  const toggleAssignUsers = obj => {
    setShowAssignUsers(!showAssignUsers)
    setSelectedPlace(obj)
    if (showAssignUsers) {
      request(true)
    }
  }
  const [showAddEditPlace, setShowAddEditPlace] = useState(false)
  const toggleAddEditPlace = () => {
    setShowAddEditPlace(!showAddEditPlace)
  }
  const { chatId, placeId } = useParams()
  useEffect(() => {
    if (!chatId && !placeId) {
      request(true)
    }
  }, [chatId, placeId])

  const ListOfPlacesUI = records => {
    return (
      <div style={{ overflow: "auto", height: "83vh" }}>
        {records &&
          records.length > 0 &&
          records.map((item, index) => {
            return (
              <div className="card mb-2  mx-2 border " key={index}>
                <div
                  className="card-body p-2 clickable"
                  onClick={() => {
                    toggleDetails(item)
                  }}
                >
                  <div className="row">
                    <div className="col-auto">
                      <img
                        src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${item.image} `}
                        loading="lazy"
                        alt=""
                        className="object-fit-cover "
                        style={{ height: "150px", width: "150px" }}
                      />
                    </div>
                    <div className="col">
                      <label>{item.name}</label>
                      <br />
                      <small className="text-muted text-truncated">
                        {`${item.address ?? ""}, ${item.sector ?? ""}, ${
                          item.city ?? ""
                        }, ${item.region ?? ""}, ${item.country} `}
                      </small>
                      <br />
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        onClick={() => toggleAssignUsers()}
                      >
                        {item.assignedTo
                          ? item.assignedToObj.firstName
                          : "Asignar"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  const filterUI = () => {
    return (
      <>
        <small className="mt-2 ">Rango de fecha</small>
        <br />
        <div className="input-group">
          <input
            type="date"
            className="form-control"
            id="from"
            value={filter.from}
            onChange={e => {
              setFilter({
                ...filter,
                from: e.target.value,
              })
            }}
          ></input>
          <input
            type="date"
            className="form-control"
            id="until"
            value={filter.until}
            onChange={e => {
              setFilter({
                ...filter,
                until: e.target.value,
              })
            }}
          ></input>
        </div>

        <small className="mt-2">Sector</small>
        <select
          className="form-control"
          id="sector"
          value={filter.sector}
          onChange={e => {
            setFilter({
              ...filter,
              sector: e.target.value,
            })
          }}
        >
          <option value="">Todos</option>
          {sectors &&
            sectors.map((item, index) => {
              return (
                <option
                  key={index}
                  value={item.name}
                >{`${item.name} (${item.count})`}</option>
              )
            })}
        </select>
        <small
          className="mt-2"
          id="name"
          value={filter.name}
          onChange={e => {
            setFilter({
              ...filter,
              name: e.target.value,
            })
          }}
        >
          Nombre
        </small>
        <input
          type="text"
          className="form-control"
          id="name"
          value={filter.name}
          onChange={e => {
            setFilter({
              ...filter,
              name: e.target.value,
            })
          }}
        ></input>
        {/* <small>Tiempo última visita</small>
			<select className='form-control' id="lastVisit">
				<option value="">Todas</option>
				<option value="15">Hace menos de 15 dias</option>
				<option value="30">Hace menos 30 dias</option>
				<option value="60">Hace menos 60 dias</option>
				<option value="masDe60">Hace más de 60 dias</option>
				<option value="withoutVisits">Sin visitas</option>
			</select> */}
        <small>Asignado A</small>
        <select
          className="form-control"
          id="assignedTo"
          onChange={e => {
            setFilter({
              ...filter,
              assignedTo: e.target.value,
            })
          }}
        >
          <option value="">Todos</option>
          {users &&
            users.map((item: any, index: number) => {
              return (
                <option
                  key={index}
                  value={item.id}
                >{`${item.firstName} ${item.lastName}`}</option>
              )
            })}
        </select>
        <small>Etapa</small>
        <select
          className="form-control"
          id="stageId"
          onChange={e => {
            setFilter({
              ...filter,
              stageId: e.target.value,
            })
          }}
        >
          <option value="">Todos</option>
          {stages &&
            stages.map((item, index) => {
              return (
                <option key={index} value={item.id}>{`${item.name}`}</option>
              )
            })}
        </select>

        <small>{t("Category")}</small>
        <select
          className="form-control"
          id="categoryId"
          onChange={e => {
            setFilter({
              ...filter,
              categoryId: e.target.value,
            })
          }}
        >
          <option value="">{t("All")}</option>
          {categories &&
            categories.map((item, index) => {
              return (
                <option key={index} value={item.id}>{`${item.name}`}</option>
              )
            })}
        </select>
        <small>{t("status")}</small>
        <select
          className="form-control"
          id="placeStatusId"
          onChange={e => {
            setFilter({
              ...filter,
              placeStatusId: e.target.value,
            })
          }}
        >
          <option value="">{t("All")}</option>
          {placeStatus &&
            placeStatus.map((item, index) => {
              return (
                <option key={index} value={item.id}>{`${item.name}`}</option>
              )
            })}
        </select>
      </>
    )
  }
  return (
    <>
      {showAddEditPlace ? (
        <AddEditPlace show={true} toggle={toggleAddEditPlace} />
      ) : null}
      {showAssignUsers ? (
        <AssignUser
          show={showAssignUsers}
          toggle={toggleAssignUsers}
          place={selectedPlace}
        />
      ) : null}

      <Card className="mt-3 shadow-sm">
        <Card.Header className="px-2 py-1">
          <Row className="mx-0">
            <Col className=" text-start">
              {/* <div className="overflow-auto text-nowrap"> */}
              <SummaryByStage filter={filter} />
              <SummaryByStatus filter={filter} />
              {/* </div> */}
            </Col>
            <Col className="text-end col-auto">
              <div className="btn-group float-end">
                <button
                  className={
                    "btn btn-primary btn-sm  " +
                    (showFilter ? "btn-primary" : "btn-secondary")
                  }
                  onClick={() => {
                    setShowFilter(!showFilter)
                  }}
                >
                  <i className="fa fa-filter"></i>
                </button>
                <button
                  className={
                    "btn  btn-sm " +
                    (showHistory ? "btn-primary" : "btn-secondary")
                  }
                  onClick={() => {
                    setShowHistory(!showHistory)
                  }}
                >
                  <i className="fa fa-history"></i>
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    if(displayMode !== "list"){
                      setShowFilter(true);
                    }
                    setDisplayMode("list" === displayMode ? "map" : "list")
                  }}
                >
                  <i
                    className={
                      "fa fa-" + (displayMode === "list" ? "list" : "map")
                    }
                  ></i>
                </button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light p-0">
          <Row className="mx-0 p-0">
            {/* FILTER */}
            {showFilter && (
              <Col xs={12} md={3} lg={2} className="bg-dark text-light">
                <button
                  className="btn btn-secondary btn-sm mb-2 mt-4"
                  onClick={() => {
                    switch (mapMode) {
                      case null:
                        setMapMode("drawPolygon")

                        return
                      case "drawPolygon":
                        setMapMode(null)

                        return
                      default:
                        setMapMode(null)
                    }
                  }}
                >
                  {mapMode === null
                    ? "Seleccionar por poligono"
                    : mapMode === "drawPolygon"
                    ? "Limpiar"
                    : ""}
                </button>
                <Row>
                  <Col style={{ overflowY: "auto", height: "70vh" }}>
                    {mapMode === null && filterUI()}

                    {mapMode === "drawPolygon" &&
                      ListOfPlacesUI(selectedPlaces)}
                  </Col>
                </Row>
                {mapMode === "drawPolygon" ? (
                  <button
                    className="btn btn-secondary btn-sm btn-block my-2 "
                    onClick={getPlacesByPolygon}
                  >
                    Obtener lugares
                  </button>
                ) : (
                  <button
                    className="btn btn-secondary btn-sm btn-block my-2"
                    onClick={async () => request()}
                  >
                    Filtrar
                  </button>
                )}
              </Col>
            )}
            <Col
              className="p-0"
              xs={12}
              md={
                showFilter && showHistory
                  ? 6
                  : showFilter || showHistory
                  ? 9
                  : 12
              }
              lg={
                showFilter && showHistory
                  ? 8
                  : showFilter || showHistory
                  ? 10
                  : 12
              }
            >
              <div className="bottomOfContainer ">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    toggleAddEditPlace()
                  }}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
              {displayMode === "list" ? (
                ListOfPlacesUI(records)
              ) : (
                <GoogleMapReact
                  ref={GoogleMapRef}
                  onClick={handleClickOnMap}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  bootstrapURLKeys={{ key: Settings.GoogleMapsKey }}
                  style={{
                    width: "100%",
                    minHeight: "300px",
                    height: "83vh",
                    position: "relative",
                  }}
                  center={{ lat: state.center.lat, lng: state.center.lng }}
                  // heatmapLibrary={true}
                  // heatmap={theHeatMapOpts}

                  defaultZoom={zoom}
                >
                  {records
                    .filter(x => x.latitude > 0)
                    .map((item, index) => {
                      return (
                        <PinComponent
                          Type={"Visit"}
                          lat={item.latitude}
                          key={index}
                          item={item}
                          toggle={toggleDetails}
                          lng={item.longitude}
                          text={item.name}
                        />
                      )
                    })}
                </GoogleMapReact>
              )}
            </Col>
            {showHistory && (
              <Col
                xs={12}
                md={3}
                lg={2}
                className="bg-light border-start"
                style={{ height: "82vh", overflow: "auto" }}
              >
                <VisitHistory />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default Visits
