import axios from 'axios';
import moment from 'moment';
import { db } from './db';
import Settings from './Settings';


const options = {
    headers: { 'Content-Type': 'application/json', 'accept': 'text/plain' },
    crossdomain: true
};
axios.interceptors.request.use(config => {
    // log a message before any HTTP request is sent
    console.log("The request ==== ", config);
    return config;
});
export default {
    postAction: async (endpoint, params, newOpts) => {
        const newId = moment().unix();
        let newItem = { url: Settings.BasePathForAPI + endpoint, params: params, id: newId, method: "post", status: "pending" };
        //Save transaction locally
        // await db.apiLog.put(newItem)
        try {
            //Send the information to the server 
            let re = await axios.post(Settings.BasePathForAPI + endpoint, params !== null ? params : null, newOpts !== null ? newOpts : options);
            //If we get here, then it means the transaction went just fine, but needs to remove the item from api
            // await db.apiLog.delete(newItem.id);

            return re;
        } catch (ex) {
            //Oh oh, something happen! lets mark the record as faulty to check later if we can re-sent same info.
            console.error(ex);
            newItem.status = "error";
            newItem.error = ex;
            await db.apiLog.put(newItem);
            // debugger;
        }

    },
    getAction: async (endpoint, params, newOpts) => axios.get(Settings.BasePathForAPI + endpoint + (params !== undefined && params !== null ? "?" + params : ""), newOpts !== undefined && newOpts !== null ? newOpts : options),
    postActionExternal: async (endpoint, params, newOpts) => axios.post(endpoint, params !== null ? params : null, newOpts !== null ? newOpts : options),
    getActionExternal: async (endpoint, newOpts) => {

        let results = axios.get(endpoint, newOpts);

        return results;
    }

}
