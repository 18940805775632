import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"

import Settings from "../../common/Settings"
import "./visit.css"

import AssignCustomer from "../Customers/AssignCustomer"

import AssignUser from "../Users/AssignUser"
import API from "../../common/API"
import AddEditVisit from "./AddEditVisit"
import { parsedUser } from "../../common/GetCurrentUser"
import { useHistory } from "react-router-dom"

import VisitGallery from "./VisitGallery"
import VisitMap from "./VisitMap"
import AddEditPlace from "./AddEditPlace"
import { t } from "i18next"
import { useTranslation } from "react-i18next"
import AssignPlaceStatus from "./AssignPlaceStatus"
import VisitList from "./VisitList"

const VisitDetails = props => {
  const [model, setModel] = useState(props.model)
  const user = parsedUser()
  const history = useHistory()
  const { t } = useTranslation("global")
  const [showCategory, setShowCategory] = useState(false)
  const [showAssignCustomer, setShowAssignCustomer] = useState(false)

  const toggleAssignCustomer = () => {
    setShowAssignCustomer(!showAssignCustomer)
  }
  const [visits, setVisits] = useState([])

  const [position, setPosition] = useState({
    lat: 18.4718609,
    lng: -69.8923187,
  })
  const requestVisits = async () => {
    let query = "placeId=" + model.id
    // query += "&OrganizationId=" + user.OrganizationId;
    let requestAPI = await API.getAction("place/VisitList", query)
    if (requestAPI.data.status === "ok") {
      setVisits(requestAPI.data.response)
    }
  }

  const requestDetails = async () => {
    let query = "id=" + model.id
    // query += "&OrganizationId=" + user.OrganizationId;
    let requestAPI = await API.getAction("place/Details", query)
    if (requestAPI.data.status === "ok") {
      setModel(requestAPI.data.response)
    }
  }

  const [showAddEditVisit, setShowAddEditVisit] = useState(false)
  const toggleAddEditVisit = () => {
    setShowAddEditVisit(!showAddEditVisit)
  }

  useEffect(() => {
    if (model) {
      setPosition({ lat: model.latitude * 1, lng: model.longitude * 1 })

      requestVisits()
    }
  }, [model])

  useEffect(() => {
    requestDetails()
  }, [])

  const toggleShowCategory = selected => {
    setShowCategory(!showCategory)
  }

  const [showAddEdit, setShowAddEdit] = useState(false)
  const toggleAddEdit = () => {
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      requestDetails()
    }
  }

  const [showAssignUsers, setShowAssignUsers] = useState(false)
  const toggleAssignUsers = () => {
    setShowAssignUsers(!showAssignUsers)
    if (showAssignUsers) {
      requestDetails()
    }
  }

  const createChat = async () => {
    try {
      let query = {
        placeId: model.id,
        createdById: user.ID,
      }
      var request = await API.postAction("chat/createChat", query)
      console.log(request)
      history.push(`/chat/${request.data.response.chatId}`)
    } catch (ex) {
      console.error(ex)
    }
  }
  const [showAssignStatus, setShowAssignStatus] = useState(false)
  const toggleAssignStatus = () => {
    setShowAssignStatus(!showAssignStatus)
    if (showAssignStatus) {
      requestDetails()
    }
  }
  const [showMap, setShowMap] = useState(true)
  const toggleMap = () => {
    setShowMap(!showMap)
  }

  const [showPhoto, setShowPhoto] = useState(false)
  const togglePhoto = () => {
    setShowPhoto(!showPhoto)
  }

  if (!model) {
    return <></>
  }

  return (
    <>
      {showAddEditVisit && (
        <AddEditVisit
          show={showAddEditVisit}
          toggle={toggleAddEditVisit}
          place={model}
        />
      )}
      {showAddEdit && (
        <AddEditPlace show={showAddEdit} toggle={toggleAddEdit} model={model} />
      )}
      {/* {showCategory ? <PhotoCategoryAssign show={showCategory} toggle={toggleShowCategory} PhotoId={selectedPhoto} /> : null} */}
      {showAssignCustomer ? (
        <AssignCustomer
          show={showAssignCustomer}
          toggle={toggleAssignCustomer}
          place={model}
        />
      ) : null}
      {showAssignUsers ? (
        <AssignUser
          show={showAssignUsers}
          toggle={toggleAssignUsers}
          place={model}
        />
      ) : null}
      {showAssignStatus ? (
        <AssignPlaceStatus
          show={true}
          toggle={toggleAssignStatus}
          place={model}
        />
      ) : null}
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="xl"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Row className="mx-0 w-100">
            <Col>
              <h3 className="d-inline">{model.name}</h3>
              <button
                className="ms-2 btn btn-sm btn-primary"
                onClick={() => toggleAddEdit()}
                type="button"
                title="Editar"
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className={"ms-2 btn btn-sm " + (showMap ? "btn-primary" : "")}
                onClick={() => toggleMap()}
                type="button"
                title="Mapa"
              >
                <i className="fa fa-map"></i>
              </button>

              <button
                className={
                  "ms-2 btn btn-sm " + (showPhoto ? "btn-primary" : "")
                }
                onClick={() => togglePhoto()}
                type="button"
                title="Galeria"
              >
                <i className="fa fa-photo-film"></i>
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <Container>
            <Row class>
              <Col xs={12} md={12} xl={4} className="p-0">
                <Card className="shadow-sm m-2">
                  <img
                    src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${model.image} `}
                    loading="lazy"
                    alt=""
                    className=" card-img-top "
                    style={{ maxHeight: "250px" }}
                  />

                  <Card.Body className="p-1">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <td>{t("Assigned to")}</td>
                          <td>
                            {model.assignedTo ? (
                              <button
                                className="clickable btn btn-outline-primary btn-sm"
                                onClick={() => toggleAssignUsers()}
                              >
                                {model.assignedToObj.firstName}{" "}
                                {model.assignedToObj.lastName}
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-sm ms-2"
                                onClick={() => toggleAssignUsers()}
                              >
                                {t("Assign")}
                              </button>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>{t("Status")}</td>
                          <td>
                            <button
                              className="btn btn-outline-primary btn-sm "
                              type="button"
                              onClick={() => toggleAssignStatus()}
                            >
                              <i
                                className={
                                  (model.placeStatus && model.placeStatus.icon
                                    ? model.placeStatus.icon
                                    : " fa fa-circle ") + " me-2"
                                }
                                style={{
                                  color:
                                    model.placeStatus &&
                                    model.placeStatus.color,
                                }}
                              ></i>
                              {model.placeStatus
                                ? model.placeStatus.name
                                : "N/A"}
                            </button>
                          </td>
                        </tr>

                        {/* <tr>
													<td>Proyecto</td>
													<td>
														{model.name}
													</td>
												</tr> */}
                        {model.category ? (
                          <tr>
                            <td>{t("Category")}</td>
                            <td>{model.category.name}</td>
                          </tr>
                        ) : null}
                        {model.stage ? (
                          <tr>
                            <td>{t("Stage")}</td>
                            <td>{model.stage.name}</td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>{t("Date of creation")}</td>
                          <td>
                            <small className="text-muted">
                              {moment(model.created).fromNow()}
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Body>
                  <Card.Footer>
                    <button
                      className="btn btn-block btn-secondary mb-4"
                      onClick={() => toggleAddEditVisit()}
                    >
                      <i className="fa fa-plus me-2"></i>
                      <span className="d-none d-md-inline">
                        {t("Add visit")}
                      </span>
                    </button>

                    <button
                      className="btn btn-block btn-primary mb-4"
                      onClick={() => createChat()}
                    >
                      <i className="fa fa-comments me-2"></i> {t("Chat")}
                    </button>
                  </Card.Footer>
                </Card>
              </Col>

              <Col xs={12} md={12} lg={8}>
                <Container>
                  {/* 						
								<h4>Contactos
									<button className="btn btn-sm btn-primary ms-2" type="button">
										<i className="fa fa-plus"></i>
									</button>

								</h4>
								<hr /> */}
                  {/* VISITS */}
                  <h4>Visitas</h4>
                  <hr />
                  <VisitList visits={visits} />

                  {/* MAP */}
                  {console.log(model)}
                  {showMap && <VisitMap model={model} />}
                  {showPhoto && (
                    <Card className="mt-4 shadow-sm">
                      <Card.Header className="px-2 p-1">
                        <h5>Fotos</h5>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <VisitGallery id={model.id} />
                      </Card.Body>
                    </Card>
                  )}
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="p-1"></Modal.Footer>
      </Modal>
    </>
  )
}

export default VisitDetails
