
import React from "react";
import moment from "moment-timezone";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <div className="fixed-bottom py-4 float-start col-auto">
    
    </div>
  );
};
