import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import moment from "moment"
import { useTranslation } from "react-i18next"

const SummaryByStatus = props => {
  const {t} = useTranslation();
  const [filter, setFilter] = useState({})
  const [records, setRecords] = useState([])
  const [show, setShow] = useState(false)
  const user = parsedUser()
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    if (filter.from) {
      Query += `&from=${moment(filter.from).format(
        "YYYY-MM-DD"
      )}&until=${moment(filter.until).format("YYYY-MM-DD")}`
    }
    if (filter.sector) {
      Query += `&sector=${filter.sector}`
    }
    if (filter.stageId) {
      Query += `&stageId=${filter.stageId}`
    }
    if (filter.categoryId) {
      Query += `&categoryId=${filter.categoryId} `
    }

    if (filter.placeVisitId) {
      Query += `&placeVisitId=${filter.placeVisitId} `
    }

    if (filter.assignedTo) {
      Query += `&assignedTo=${filter.assignedTo} `
    }
    var request = await API.getAction("place/SummaryByStatus", Query)
    setRecords(request.data.response)
  }
  useEffect(() => {
    console.log(props.filter)
    setFilter(props.filter)
  }, [props])
  useEffect(() => {
    bindDataRemote()
  }, [filter])
  return (
    <>
    <button className="ms-2 btn btn-secondary btn-sm ms-2" onClick={() => { setShow(!show) }}>
      {t('Status')}
      </button>
      {show && records.map((item, index) => {
        return (
          <div className="badge me-2 text-dark" key={index}>
            <i className={(item.icon?? "fa fa-circle ") + " ms-2  me-2"} style={{
              color: item.color
            }}></i>
            <span className="d-none d-md-inline">{item.name}</span> {item.count}
          </div>
        )
      })}
    </>
  )
}

export default SummaryByStatus
