import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { useHistory, useParams } from "react-router-dom"
import { Routes } from "../../routes"
import Settings from "../../common/Settings"
import moment from "moment"
import Pagination from "../core/Pagination"
import Filter from "../core/Filter/Filter"

const VisitHistory = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [filterObj, setFilterObj] = useState({})
  const filterFields = [
    {
      name: "name",
      htmlType: "text",
      title: "Name",
    },
  ]
  const history = useHistory()
  const [quantity, setQuantity] = useState<number>(20)
  const [page, setPage] = useState<number>(0)
  const [quantityOfPages, setQuantityOfPages] = useState<number>(1)
  const user = parsedUser()
  const [records, setRecords] = useState([])

  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  const requestHistories = async () => {
    let query =
      "page=" +
      page +
      "&quantity=" +
      quantity +
      "&organizationId=" +
      user.OrganizationId

    filterFields &&
      filterFields.forEach(item => {
        query += `&${item.name}=${filterObj[item.name] ?? ""}`
      })
    let requestAPI = await API.getAction("place/history", query)

    if (requestAPI.data.status === "ok") {
      setRecords(requestAPI.data.response)

      setQuantityOfPages(requestAPI.data.quantityOfPages)
    }
  }
  useEffect(() => {
    if (filterObj && Object.keys(filterObj).length > 0) {
      console.log(filterObj)
      requestHistories()
    }
  }, [filterObj])
  useEffect(() => {
    requestHistories()
  }, [])
  useEffect(() => {
    requestHistories()
  }, [page])
  return (
    <div>
      {" "}
      <h5 className="mt-4">
        Historial
        <button
          className={"btn btn-sm ms-2 " + (showFilter ? "btn-primary" : "")}
          onClick={() => setShowFilter(!showFilter)}
        >
          <i className="fa fa-filter "></i>
        </button>
      </h5>
      <hr className="p-0 m-0" />
      {showFilter && (
        <Filter
          fields={filterFields}
          filterAction={() => {
            requestHistories()
          }}
          setFilterFields={getFilterValues}
        />
      )}
      {records &&
        records.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="my-2 card bg-white shadow-md clickable"
              onClick={() => {
                history.push(Routes.PlaceDetails.clean + item.placeId)
              }}
            >
              <img
                src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${item.image} `}
                loading="lazy"
                alt=""
                className=" card-img-top "
                style={{ height: "150px" }}
              />

              <div className="card-body p-1">
                <b>{item.placeName}</b>
                <br />
                <small>{item.note}</small>
                <br />
              </div>
              <div className="card-footer p-1">
                <span className="fs-6 me-2">{`${item.author.firstName ?? ""} ${
                  item.author.lastName ?? ""
                }`}</span>

                <small className="text-muted">
                  {moment(item.created).fromNow()}
                </small>
              </div>
            </div>
          )
        })}
      <Pagination
        currentPage={page}
        totalPages={quantityOfPages}
        onPageChanged={setPage}
      />
    </div>
  )
}

export default VisitHistory
