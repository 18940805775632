import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { parsedUser } from "../../common/GetCurrentUser";
import API from "../../common/API";

const AssignUser = (props) => {
  const user = parsedUser();
  const [records, setRecords] = useState([]);
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId;

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("users/list", Query);

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response;
      setRecords(result);
    }
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  const handleAssignUser = async (item) => {
    console.log(item)
    let Query = `placeId=${props.place.id}&userId=${item? item.userId: ""}`;
    let requestAPI = await API.getAction("place/AssignUser", Query);
    props.toggle();
  };
  return (
    <>
      <Modal show={props.show} onHide={props.toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                {records &&
                  records.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {item.firstName} {item.lastName}
                        </td>
                        <td className="text-end">
                          {item.id !== props.place.assignedTo && (
                            
                          
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              handleAssignUser(item);
                            }}
                          >
                            Asignar
                          </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.place.assignedTo ? (
            
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              handleAssignUser(null);
            }}
          >
            Desvincular
          </button>
          
        ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignUser;
