import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../../common/API';
import { Routes } from "../../routes";
import ClearUser from '../../common/ClearUser';
import { parseJwt } from '../../common/helpers';

const Login = props => {
  const [state, setState] = useState({
    Email: "", Password: "",
    UI: {
      IsLoading: false,
      validated: false
    }
  });

  useEffect(() => {

    ClearUser();
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    console.log("form validity", form.checkValidity());
    if (form.checkValidity() === false) {

      event.stopPropagation();

    } else {

      console.log("Continue to next process", state.value);



      try {
        setState({ ...state, UI: { ...state.UI, IsLoading: true } });

        let query = {
          username: state.Email,
          password: state.Password
        };

        setState({ ...state, UI: { ...state.UI, validated: true } });
        
        let request = await API.postAction("account/RequestToken", query);

        let response = request.data;
        console.log("token", response.token);
        console.log("Parsed token", parseJwt(response.token));
        localStorage.setItem("LogedUser", response.token);
        props.history.push(Routes.Dashboard.path);
      } catch (err) {
        console.log("Error", err);
        setState({ ...state, UI: { ...state.UI, Error: err } });


      } finally {
        setState({ ...state, UI: { ...state.UI, IsLoading: false } });
      }
    }

  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>

          {/* <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}> */}

          <Row className="justify-content-center form-bg-image" >
            <div className='col-12 text-center'>

            </div>
            <Col xs={12} className="d-flex align-items-center justify-content-center">

              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">

                  <h3 className='my-4'>Shearly <b>BI</b></h3>
                </div>
                <Form className="mt-4 shadow-sm"

                  noValidate validated={state.UI.validated} onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4 ">
                    <Form.Label>E-Mail</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com"
                        value={state.Email}
                        onChange={(e) => { setState({ ...state, Email: e.target.value }); }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Favor entrar un correo valido
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password"
                          value={state.Password}
                          onChange={(e) => { setState({ ...state, Password: e.target.value }); }} />
                        <Form.Control.Feedback type="invalid">
                          Campo requerido
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4 text-right">

                      <Card.Link className="small text-end" as={Link} to={Routes.ForgotPassword.path}>Recuperar contraseña</Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100"
                    disabled={state.UI.IsLoading}
                  >
                    {state.UI.IsLoading ?

                      <span className="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true"></span> : null}
                    Ingresar
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4 d-none">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Register.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default Login;