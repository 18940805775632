import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const PlaceStatusComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("status") ?? ""}
      getRecordsPath={`placeStatus/list`}
      addEditRecordPath={`placeStatus/AddEdit`}
      deleteRecordPath="placeStatus/Delete"
      fieldsList={[
        {
          name: "id",
          title: t("id"),
        },
        {
          name: "name",
          title: t("name"),
        },
        {
          name: "icon",
          title: t("icon"),
          htmlType: "icon",
          cssClass: "text-center",
        },

        {
          name: "color",
          title: t("color"),
          htmlType: "color",
          cssClass: "text-center",
        },
       
      ]}
      fields={[
        {
          
          title: t("Id") ?? "",
          name: "id",
          htmlType: "text",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "icon",
          htmlType: "text",
          dbType: "string",
          title: t("icon") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
        {
          name: "color",
          htmlType: "color",
          dbType: "string",
          title: t("color") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
     
      ]}
    />
  )
}

export default PlaceStatusComponent
