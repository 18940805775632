import React, { useEffect, useState } from "react"
import { Card, Col, Dropdown, Modal, Row } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import moment from "moment"
import Settings from "../../common/Settings"

import GoogleMapReact from "google-map-react"
import PinComponent from "../Common/PinComponent"
import { useHistory } from "react-router-dom"
import { Routes } from "../../routes"
import { useTranslation } from "react-i18next"
import { confirmAlert } from "react-confirm-alert"

const Conversations = props => {
  const GoogleMapRef = React.createRef()
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(null)
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const history = useHistory()
  const { t } = useTranslation("global")
  const [theMap, setTheMap] = useState(null)
  const [theMapsReference, setTheMapsReference] = useState(null)
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }
  const user = parsedUser()
  const bindDataRemote = async () => {
    let query = `userId=${user.ID}&OrganizationID=${selectedOrganization ?? ""}`
    var request = await API.getAction("Chat/Chats", query)
    if (request.data.status === "ok") {
      var result = request.data.response
      console.log(result)
      setRecords(result)
    }
  }

  const bindDataDetailsRemote = async () => {
    var request = await API.getAction(
      "Chat/ChatDetails",
      "id=" + props.chatId + "&userId=" + user.ID
    )
    if (request.data.status === "ok") {
      var result = request.data.response
      setRecord(result[0])
      console.log(result)
      //setRecords(result);
    }
  }

  const bindDataConversationRemote = async () => {
    var request = await API.getAction(
      "Chat/Conversation",
      "id=" + record.id + "&userId=" + user.ID
    )
    if (request.data.status === "ok") {
      var result = request.data.response
      console.log(result)
      setMessages(result)
    }
  }

  const sendMessage = async () => {
    let query = {
      chatId: record.id,
      senderId: user.ID,
      message: message,
    }
    setMessage("")
    var request = await API.postAction("Chat/SendMessage", query)
    if (request.data.status === "ok") {
      var result = request.data.response
      console.log(result)
      bindDataConversationRemote()
    }
  }
  const [organizations, setOrganizations] = useState([])
  const requestOrganizations = async () => {
    let theUser = parsedUser()

    console.log("render side bar", new Date(), user, theUser)
    if (theUser === null) {
      return
    }
    let requestAPI = await API.getAction(
      "Organization/OrganizationsWhereTheUserBelong?AccountId=" + theUser.ID
    )

    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response)
    }
  }

  const leaveChat = chat => {
    confirmAlert({
      closeOnClickOutside: false,
      message: t("Are you sure you want to leave?") ?? "",
      buttons: [
        {
          label: t("Yes"),
          onClick: async () => {
            let theUser = parsedUser()

            console.log("render side bar", new Date(), user, theUser)
            if (theUser === null) {
              return
            }
            let requestAPI = await API.getAction(
              "chat/leaveChat?UserId=" + theUser.ID + "&ChatId=" + chat.id
            )

            if (requestAPI.data.status === "ok") {
              await bindDataRemote()
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("no")
          },
        },
      ],
    })
  }

  const listOfConversations = () => {
    let items =
      records &&
      records.map((item: any, index) => {
        console.log(item)
        let path = `${Settings.BasePath}files/thumb/${item.picture}`
        return (
          <Card key={index} className="my-2 shadow-sm clickable">
            <Card.Body className="p-1">
              <Row>
                <Col className="col-auto" onClick={() => setRecord(item)}>
                  <img
                    alt=""
                    src={path}
                    className="img-fluid rounded  mx-0"
                    style={{ height: 50, width: 50 }}
                  ></img>
                </Col>
                <Col onClick={() => setRecord(item)}>
                  <Row>
                    <Col>
                      <b>{item.title}</b>
                      <br />
                      <small>
                        <b>
                          {" "}
                          {item.members &&
                            item.members
                              .filter(x => x.memberId !== user.ID)
                              .map((member, memberIndex: number) => {
                                console.log(member)
                                return (
                                  <span key={memberIndex}>
                                    {member.name + ": "}
                                  </span>
                                )
                              })}
                        </b>
                        {item.lastMessage}
                        {item.lastMessageDate && (
                          <span className="text-muted ms-2">
                            {moment(item.lastMessageDate).fromNow()}
                          </span>
                        )}
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-auto text-end">
                  {item.unreadMessages > 0 && (
                    <div className="badge bg-danger">{item.unreadMessages}</div>
                  )}
                  <Dropdown>
                    <Dropdown.Toggle variant="link">
                      <i className="fas fa-ellipsis-v"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => leaveChat(item)}>
                        {t("Leave chat")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      })

    return items
  }
  const listMessages = () => {
    let actualUser = null
    let items =
      messages &&
      messages.map((item, index) => {
        let path = `${Settings.BasePath}files/thumb/${item.picture}`
        let messageRow = (
          <div key={index}>
            {actualUser !== item.senderId && (
              <p className="m-0 mt-2 p-0 fw-bold border-top">
                {item.senderName} {item.senderLastName}
              </p>
            )}

            <div className="message">
              <div className="flex-row">
              {item.message}
              {item.created && (
                <small className="text-muted ms-2 messageTime">
                  {moment(item.created).fromNow()}
                </small>
              )}
              <div className="float-end">
                {item.read && <i className="fas fa-check-double text-blue" title={item.read}></i>}
              </div>
              </div>
            </div>
            {/* {actualUser !== null && actualUser !== item.senderId && (
              <hr className="p-1 m-0" />
            )} */}
          </div>
        )

        actualUser = item.senderId
        return messageRow
      })

    return items
  }
  useEffect(() => {
    if (record) {
      bindDataConversationRemote()
      intervalGetMessages = setInterval(bindDataConversationRemote, 5000)
    } else {
      bindDataRemote()
      intervalGetConversations = setInterval(bindDataRemote, 15000)
    }

    return () => {
      clearInterval(intervalGetMessages)
      clearInterval(intervalGetConversations)
    }
  }, [record])

  useEffect(() => {
    bindDataRemote()
  }, [selectedOrganization])

  let intervalGetMessages = null
  let intervalGetConversations = null

  useEffect(() => {
    if (props.chatId) {
      bindDataDetailsRemote()
    }
  }, [props.chatId])

  useEffect(() => {
    bindDataRemote()
    requestOrganizations()
  }, [])

  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      className="chatContainer modalRight"
    >
      <Modal.Header closeButton>
        {record ? (
          <Row className="mx-0">
            <Col className="col-auto">
              <button
                className="btn  me-2 btn-link"
                onClick={() => setRecord(null)}
                type="button"
              >
                <i className="fa fa-chevron-left"></i>
              </button>
              <img
                alt=""
                src={`${Settings.BasePath}files/thumb/${record.picture}`}
                className="img-fluid rounded  mx-0 d-inline me-2"
                style={{ height: 22, width: 22 }}
              ></img>

              <span
                className="clickable"
                onClick={() => {
                  history.push(Routes.PlaceDetails.clean + record.placeId)
                }}
              >
                <b> {record.title}</b>
              </span>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="col-auto pt-2"><b>{t('Conversations')}</b></Col>
            <Col>
              <select
                className="form-control form-control-sm"
                id="organization"
                value={selectedOrganization}
                onChange={e => setSelectedOrganization(e.target.value)}
              >
                <option value="">-</option>
                {organizations &&
                  organizations.map((x, index) => (
                    <option value={x.id} key={index}>
                      {x.name}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
        )}
      </Modal.Header>
      <Modal.Body
        className="p-0 "
        style={{ overflow: "auto", minHeight: "81vh" }}
      >
        <Row className="mx-0">
          <Col xs={12} className="my-2">
            {!record ? (
              listOfConversations()
            ) : (
              <>
                <GoogleMapReact
                  ref={GoogleMapRef}
                  // onClick={this.handleClick}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  bootstrapURLKeys={{ key: Settings.GoogleMapsKey }}
                  style={{
                    width: "100%",
                    height: "200px",
                    position: "relative",
                  }}
                  center={{
                    lat: record.placeLatitude,
                    lng: record.placeLongitude,
                  }}
                  defaultZoom={17}
                >
                  <PinComponent
                    Type={"Visit"}
                    lat={record.placeLatitude}
                    //    item={model}
                    // toggle={toggleDetails}
                    lng={record.placeLongitude}
                    text={record.title}
                  />
                </GoogleMapReact>
                {listMessages()}
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      {record && (
        <Modal.Footer className="bg-light">
          <Row className="mx-0 w-100">
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Message"
                name="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Col>
            <Col className="text-right col-auto">
              <button
                disabled={message.length === 0}
                className="btn btn-primary"
                type="button"
                onClick={sendMessage}
              >
                Send
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default Conversations
