import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import Settings from "../../common/Settings"
import { useTranslation } from "react-i18next"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"

const VisitListItem = props => {
  const [item, setItem] = useState(props.model)
  const [showComments, setShowComments] = useState(false)
  const { t } = useTranslation("global")
  const user = parsedUser()
  const [comment, setComment] = useState("")
  const [comments, setComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const requestComments = async () => {
    let query = "visitId=" + item.id
    let requestAPI = await API.getAction("PlaceVisitComment/List", query)
    if (requestAPI.data.status === "ok") {
      setComments(requestAPI.data.response)
    }
  }

  const submitMessage = async e => {
    try {
      if (comment === "") return

      if (e.key === "Enter") {
        let query = {
          visitId: item.id,
          comment: comment,
          accountId: user.ID,
        }
        setIsLoading(true)
        const request = await API.postAction("PlaceVisitComment/AddEdit", query)
        if (request && request.data.status === "ok") {
          setComment("")
          await requestDetails()
          await requestComments()
          // setShowComments(false)
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const requestDetails = async () => {
    let query = "id=" + item.id
    // query += "&OrganizationId=" + user.OrganizationId;
    let requestAPI = await API.getAction("place/VisitDetails", query)
    if (requestAPI.data.status === "ok") {
      setItem(requestAPI.data.response)
    }
  }

  useEffect(() => {
    if (showComments) {
      requestComments()
    }
  }, [showComments])

  return (
    <Card className="mb-2 shadow-sm">
      <Card.Header className="px-2 py-1">
        {item.createdBy && (
          <b className="me-2">
            {item.createdBy.firstName + " " + item.createdBy.lastName}
          </b>
        )}{" "}
        <small className="text-muted">{moment(item.created).fromNow()}</small>
      </Card.Header>

      <Card.Body className="px-2 py-1">
        <p className="card-text">{item.note}</p>
        <img
          loading="lazy"
          className="rounded"
          src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${item.image}`}
          alt="..."
          style={{ height: "300px", objectFit: "cover", width: "100%" }}
        />
      </Card.Body>
      <Card.Footer className="px-2 py-1">
        <button
          className="btn btn-sm clickable"
          title={t("Comment")}
          onClick={() => {
            if (!showComments) {
              document.getElementById("commentBox")?.focus()
            }
            setShowComments(!showComments)
          }}
        >
          <i className="far fa-comment"></i>
          <span className="ms-2">{item.commentsCount}</span>
        </button>
        <button
          className="ms-2 btn  btn-sm clickable"
          title={t("See map")}
          type="button"
          onClick={() => {
            let fullAddress = ""
            fullAddress = `${item.latitude},${item.longitude}`
            //`${model.item.streetAddress ?? ""}, ${model.item.city ?? ""}, ${model.item.state ?? ""}, ${model.item.country}`;
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${fullAddress}`,
              "MapWindow",
              "width=1280,height=840"
            )
          }}
        >
          <i className="fa-solid fa-map-pin"></i>
        </button>
        {showComments && (
          <>
            {comments && comments.length > 0 && (
              <ul>
                {comments.map((comment: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className="list-group-item list-group-item-action"
                    >
                      <b>
                        {comment.account.firstName +
                          " " +
                          comment.account.lastName}
                      </b>{" "}
                      <small className="text-muted me-2">
                        {" "}
                        {moment(comment.created).fromNow()}
                      </small>
                      <p>{comment.comment}</p>
                    </li>
                  )
                })}
              </ul>
            )}

            <input
              id="commentBox"
              type="text"
              disabled={isLoading}
              className="form-control form-control-sm mt-4 mb-2"
              placeholder="Comentar"
              value={comment}
              onKeyDown={submitMessage}
              onChange={e => setComment(e.target.value)}
            />
          </>
        )}
      </Card.Footer>
    </Card>
  )
}

export default VisitListItem
